import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header/Header";  // Correct path to Header component
import Hero from "./components/Hero/Hero";  // Correct path to Hero component
import About from "./components/About/About";  // Correct path to About component
import Projects from "./components/Projects/Projects";  // Correct path to Projects component
import Contact from "./components/Contact/Contact";  // Correct path to Contact component
import Footer from "./components/Footer/Footer";  // Correct path to Footer component
import { ThemeProvider } from './context/ThemeContext';  // Correct path to ThemeContext

function App() {
    return (
        <ThemeProvider>
            <Router>
                <div>
                    <Header />
                    <Hero />
                    <About />
                    <Projects />
                    <Contact />
                    <Footer />
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
