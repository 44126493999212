import React from "react";
import "./Footer.css"; // Import the CSS file

const Footer = () => (
    <footer>
        <p>&copy; 2024 Ashish Ranjan. Built with React.</p>
    </footer>
);

export default Footer;
