import React from "react";
import "./Projects.css";  // Importing the external CSS file
import projectsData from "../../data/projects.json";  // Go up two levels to access /data

const Projects = () => {
    const projects = projectsData;

    return (
        <section id="projects">
            <h2>My Projects</h2>
            <div className="projects-grid">
                {projects.map((project) => (
                    <div className="project-card" key={project.id}>
                        <div className="preview">
                            <img
                                src={`/thumbnails/${project.id}.jpg`}
                                alt={`${project.title} preview`}
                                onError={(e) => (e.target.src = "/fallback-thumbnail.jpg")}
                            />
                        </div>
                        <div className="content">
                            <h3>{project.title}</h3>
                            <div className="button-wrapper">
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="view-project-btn"
                                >
                                    View Project
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Projects;
