import React from "react";
import "./Contact.css";  // Import the external CSS file
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Contact = () => (
    <section id="contact">
        <h2>Contact Me</h2>
        <p>Feel free to connect with me through my social channels:</p>
        <div className="social-links">
            <a href="https://github.com/Its-Ashish-RanJan" target="_blank" rel="noopener noreferrer">
                <FaGithub />
            </a>
            <a href="https://www.linkedin.com/in/ashish-ranjan-145635326" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
            </a>
        </div>
    </section>
);

export default Contact;
