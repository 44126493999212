import React, { createContext, useState, useEffect } from 'react';

// Create a context for the theme
const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('light'); // Default theme is light

    // Effect to apply the system's preferred color scheme on initial load
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');  // Retrieve saved theme from localStorage
        if (savedTheme) {
            setTheme(savedTheme);
        } else {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (prefersDarkMode) {
                setTheme('dark');
            }
        }

        // Listen for theme changes and update the theme state
        const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
        const changeHandler = (e) => {
            setTheme(e.matches ? 'dark' : 'light');
        };

        mediaQueryList.addEventListener('change', changeHandler);

        return () => {
            mediaQueryList.removeEventListener('change', changeHandler);
        };
    }, []);

    // Apply theme as a class on the document body
    useEffect(() => {
        if (theme === 'dark') {
            document.body.classList.add('dark');
            localStorage.setItem('theme', 'dark'); // Save the theme to localStorage
        } else {
            document.body.classList.remove('dark');
            localStorage.setItem('theme', 'light'); // Save the theme to localStorage
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
