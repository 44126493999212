import React from "react";
import "./Hero.css"; // Import the CSS file

const Hero = () => (
    <section className="hero-section">
        <h1>Hi, I'm Ashish Ranjan</h1>
        <p>Welcome to my portfolio! Check out my latest projects below.</p>
        <a href="#projects" className="cta-button">
            View Projects
        </a>
    </section>
);

export default Hero;
