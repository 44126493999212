import React from "react";
import "./Header.css"; // Import the CSS file

const Header = () => (
    <nav className="nav">
        <div className="logo">Portfolio</div>
        <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
        </ul>
    </nav>
);

export default Header;
