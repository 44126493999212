import React from "react";
import "./About.css";  // Import the external CSS file

const About = () => (
  <section id="about">
    <h2>About Me</h2>
    <p>
      Hi, I’m Ashish Ranjan, an undergraduate student passionate about web
      development and technology. I specialize in building dynamic,
      user-friendly web applications using tools like React, JavaScript, and
      modern web technologies.<br></br>I enjoy turning ideas into interactive
      experiences that are both functional and visually appealing. From crafting
      responsive designs to writing clean, efficient code, I strive to create
      solutions that make an impact.<br></br>
      Feel free to explore my portfolio
      and connect with me—I’m always excited to collaborate on innovative
      projects and bring creative ideas to life!
    </p>
  </section>
);

export default About;
